<template lang="pug">
  #app
    Home
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style lang="sass">
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap")

#app
  font-family: Poppins
  -webkit-font-smoothing: antialiased
  -moz-osxy-font-smoothing: grayscale
  color: #433C31
  margin: -8px

@media only screen and (min-width: 750px)
  .body
    overflow: hidden
</style>
